var jQuery = require('jquery');
require('jquery.easing');

window.jQuery = jQuery;
window.$ = jQuery;


// var Popper = require('popper.js');
// window.Popper = Popper;
// require('moment');
require('bootstrap');
// require('bootstrap/js/dist/alert');
// require('bootstrap/js/dist/button');
// require('bootstrap/js/dist/carousel');
// require('bootstrap/js/dist/util');
// require('bootstrap/js/dist/collapse');
// require('bootstrap/js/dist/dropdown');
// require('bootstrap/js/dist/modal');
// require('bootstrap/js/dist/popover');
// require('bootstrap/js/dist/scrollspy');
// require('bootstrap/js/dist/tab');
// require('bootstrap/js/dist/tooltip');



// require('bootstrap-select');
// require('pc-bootstrap4-datetimepicker');
// require('bootstrap-datepicker');
// require('bootstrap-material-design');

// require('jquery.placeholder');
require('jquery-sticky');
// require('jquery-match-height');

require('owl.carousel');
require('magnific-popup');
// require('jquery-match-height');
// require('lazysizes');
// require('picturefill');

// require('jquery-ias');
// require('jquery-ias/src/callbacks.js');
// require('jquery-ias/src/extension/paging');
// require('jquery-ias/src/extension/spinner');
// require('jquery-ias/src/extension/trigger');
// require('jquery-ias/src/extension/history');
// require('jquery-ias/src/extension/noneleft');

// require('@fortawesome/fontawesome-free');

var Cookie = require('js-cookie');
window.Cookie = Cookie;

// var elements = document.querySelectorAll('input,select,textarea');

// for (var i = elements.length; i--;) {
//     elements[i].addEventListener('invalid', function () {
//         this.scrollIntoView(false);
//     });
// }

var async = async ||[];
while (async.length) { // there is some syncing to be done
    var obj = async.shift();
    if (obj[0] == "ready") {
        $(obj[1]);
    } else if (obj[0] == "load") {
        $(window).load(obj[1]);
    }
}
async = {
    push: function(param) {
        if (param[0] == "ready") {
            $(param[1]);
        } else if (param[0] == "load") {
            $(window).load(param[1]);
        }
    }
};

// DOM Ready functions using custom async function so it only funs when jQuery has been fully loaded

// $(function() {
async.push(["ready", function() {


    // HTML5 validation scroll offset fixed header
    var delay = 0;
    var offset = 150;

    document.addEventListener('invalid', function(e) {
        $(e.target).addClass("invalid");
        $('html, body').animate({ scrollTop: $($(".invalid")[0]).offset().top - offset }, delay);
    }, true);
    document.addEventListener('change', function(e) {
        $(e.target).removeClass("invalid")
    }, true);

    // if (!window._preventBMD) {
    //     $('body').bootstrapMaterialDesign();
    // }
    // $('.nav-link').button('dispose')
    // Initialise the Ajax funxctions - put most of your js calls in this function
    // or if you need to reload them after an ajax call
    // initAjaxJS();

    // $(window).load(function() {
    // });

    // Universal Analytics event tracking
    if (typeof ga !== 'undefined') {

        // track all phone number click events
        $('a[href^="tel:"]').on('click', function(e) {
            var href = $(this).attr('href');
            var value = href.split(':');

            // console.log('clicked', $(this).text(), value[1]);
            ga('send', 'event', 'Phone', 'click', value[1]);
        });

        // track all email click events
        $('a[href^="mailto:"]').on('click', function(e) {

            var href = $(this).attr('href');
            var value = href.split(':');

            // console.log('clicked', $(this).text(), value[1]);
            ga('send', 'event', 'Email', 'click', value[1]);
        });

    }

    //load external script on tab show customer reviews
    $('a#customer-reviews[data-toggle="tab"]').on('shown.bs.tab', function (event) {
        $.getScript("https://apps.elfsight.com/p/platform.js");
    })

    //load external option selected for mobile customer reviews
    $("select.dealer-select").change(function(){
        var selectedReviews = $(this).children("option:selected").val();

        if (selectedReviews == 'tab-customer-reviews') {
            $.getScript("https://apps.elfsight.com/p/platform.js");
        }
    });

    //Measure and quote button
    $(window).on('scroll', function() {
        if ($(window).scrollTop() > 300) {
            $(".measure-btn-mob").addClass('show');
        } else {
            $('.measure-btn-mob').removeClass('show');
        }
    });

    // Scroll to top

    $('#top').on('click', function(e) {
        e.preventDefault();
        $("html, body").animate({ scrollTop: 0 }, 1500);
        return true;
    });

    $('#top-mob').on('click', function(e) {
        e.preventDefault();
        $("html, body").animate({ scrollTop: 0 }, 1500);
        return true;
    });

    // $('.selectpicker').selectpicker();

    // $('.datepicker').datetimepicker({
    //     format: 'DD/MM/YYYY',
    //     showTodayButton: true,
    //     showClear: true
    // });

    $('#login-modal').on('shown.bs.modal', function() {
        $('#email').trigger('focus')
    });

    // Off canvas mobile nav
    $('[data-toggle="offcanvas"]').on('click', function() {
        $('.offcanvas').toggleClass('active');
        $(this).toggleClass('close-btn')

    });

    $('select[data-toggle="tabOption"]').on('change', function(e) {
        e.preventDefault();
        let target = e.target.value;
        target = $('#' + target);
        target.addClass('active');
        target.siblings().removeClass('active');
    })

    // $("#mob-links.mob-header-links").sticky({topSpacing:0});
    // $("#main-nav.navbar").sticky({topSpacing:0});
    if ($("#sticky-wrapper").length > 0) {
        $("#sticky-wrapper").sticky({ topSpacing: 54 });
    }


    // if ($('.main-slider').length) {
    //     $('.main-slider').owlCarousel({
    //         responsiveRefreshRate: 1,
    //         loop: true,
    //         margin: 0,
    //         responsiveClass: true,
    //         items: 1,
    //         nav: false,
    //
    //         // navText : ["Prev","Next"],
    //         loop: true,
    //         dots: true,
    //         dotsEach: true,
    //         autoplay: false,
    //         autoplayTimeout: 10000,
    //         smartSpeed: 750,
    //         animateOut: 'fadeOut',
    //         // animateIn: 'slideRight',
    //         mouseDrag: false,
    //         touchDrag: false,
    //         pullDrag: false,
    //         freeDrag: false,
    //         responsive: {
    //
    //             0: {
    //
    //             },
    //
    //             576: {
    //
    //             },
    //             768: {
    //                 dots: true
    //             }
    //         }
    //
    //     })
    // }

    if ($('.product-slider').length) {
        $('.product-slider').owlCarousel({
            responsiveRefreshRate: 1,
            loop: true,
            margin: 0,
            responsiveClass: true,
            lazyLoad: true,
            items: 1,
            nav: true,
            center: true,
            navText: ["<img src='/img/site/icons/arrow-lh-bl.svg' alt='previous image' />", "<img src='/img/site/icons/arrow-rh-bl.svg' alt='next image' />"],
            loop: true,
            // dots: true,
            dotsEach: true,
            autoplay: false,
            autoplayTimeout: 10000,
            smartSpeed: 1200,
            // animateOut: 'fadeOut',
            // animateIn: 'slideRight',
            mouseDrag: false,
            touchDrag: false,
            pullDrag: false,
            autoWidth: true,
            freeDrag: false,
            responsive: {

                0: {

                },

                576: {},
                768: {
                    dots: true
                }
            }

        })
    }

    if ($('.products-carousel').length) {
        $('.products-carousel').owlCarousel({
            center: true,
            // items:2,
            loop: true,
            margin: 0,
            dots: true,
            dotsEach: true,
            responsive: {
                0: {
                    items: 1
                },
                600: {
                    items: 2
                },
                800: {
                    items: 2
                },
                1000: {
                    items: 3
                },
                1800: {
                    // items:4,
                    // startPosition: 2,
                    items: 6
                    // autoWidth: true
                }
            }
        });
    }

    if ($('.testimonial-carousel').length) {
        $('.testimonial-carousel').owlCarousel({
            responsiveRefreshRate: 1,
            loop: true,
            margin: 0,
            responsiveClass: true,
            items: 1,
            nav: false,
            dot: true,
            autoplay: true,
            autoplayTimeout: 12000,
            smartSpeed: 750

        });
    }

    $('.scrollup').on('click', function() {
        $("html, body").animate({
            scrollTop: 0
        }, 600);
        return false;
    });

    if ($('.form-enquiry').length) {
        $('.product-enquiry-wrap').show();
    }

    if ($('.down-scroll').length) {
        // Page scroll
        $('.down-scroll').on('click', function(event) {
            var $anchor = $(this);

            $('html, body').stop().animate({
                scrollTop: $($anchor.attr('href')).offset().top - 104
            }, 500, 'easeInOutExpo');

            event.preventDefault();
        });
    }

    // Product enquiry button
    $('.product-enquiry').on('click', function(e) {
        e.preventDefault();
        var target = $(this).attr('href');

        $('html,body').animate({
            scrollTop: $(target).offset().top - 100
        }, 1000, 'swing');
    });

    scrollToFormError();

    $(window).on('scroll', function() {
        if ($(this).scrollTop() > 125) {
            $('#main-nav').addClass('page-down');
        } else {
            $('#main-nav').removeClass('page-down');
        }
    });



    if ($('.popup-youtube').length) {
        $('.popup-youtube').magnificPopup({
            disableOn: 700,
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: false,
            fixedContentPos: false,
            gallery: {
                enabled: true
            },
        });
    }

    //Gallery popup
    $('.pop').magnificPopup({

        type: 'image',
        gallery: {
            enabled: true
        },
        callbacks: {

            buildControls: function() {
                // re-appends controls inside the main container
                this.contentContainer.append(this.arrowLeft.add(this.arrowRight));
            }

        },
        image: {
            markup: '<div class="mfp-figure">' +
                '<div class="mfp-close"></div>' +
                '<div class="mfp-img"></div>' +
                '<div class="mfp-bottom-bar">' +
                '<div class="mfp-title"></div>' +
                '</div>' +
                '</div>', // Popup HTML markup. `.mfp-img` div will be replaced with img tag, `.mfp-close` by close button

            cursor: 'mfp-zoom-out-cur',
            titleSrc: 'title',
            verticalFit: true, // Fits image in area vertically
            tError: '<a href="%url%">The image</a> could not be loaded.' // Error message
        },
        iframe: {
            patterns: {
                youtube: {
                    index: 'youtube.com',
                    id: 'v=',
                    src: '//www.youtube.com/embed/%id%?rel=0&autoplay=1'
                }
            }
        }
    });

    if ($('.sq-carousel').length) {
        var owl = $('.sq-carousel');
        owl.owlCarousel({
            autoplay: true,
            items: 1,
            loop: true,
            dots: false,
            smartSpeed: 750,
            nav: true,
            navContainer: '.custom-nav',
            onInitialized: counter, //When the plugin has initialized.
            onTranslated: counter //When the translation of the stage has finished.
        });

        function counter(event) {
            var element = event.target; // DOM element, in this example .owl-carousel
            var items = event.item.count; // Number of items
            var item = event.item.index + 1; // Position of the current item

            // it loop is true then reset counter from 1
            if (item > items) {
                item = item - items
            }
            $('.counter').html("Slide " + item + " of " + items)
        }
    }

    if ($('.iframe-cards').length) {
        $('.iframe-cards').each(function() {
            $(this).magnificPopup({
                delegate: '.iframe-link',
                type: 'iframe',
                tLoading: 'Loading image #%curr%...',
                // mainClass: 'mfp-img-mobile mfp-figure',
                mainClass: 'iframe-visualiser',
                gallery: {
                    enabled: false,
                    navigateByImgClick: true,
                    preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
                },
                iframe: {
                    markup: '<div class="mfp-iframe-scaler">' +
                        '<div class="mfp-close"></div>' +
                        '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
                        '</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button

                    patterns: {
                        youtube: {
                            index: 'youtube.com/', // String that detects type of video (in this case YouTube). Simply via url.indexOf(index).

                            id: 'v=', // String that splits URL in a two parts, second part should be %id%
                            // Or null - full URL will be returned
                            // Or a function that should return %id%, for example:
                            // id: function(url) { return 'parsed id'; }

                            src: '//www.youtube.com/embed/%id%?autoplay=1' // URL that will be set as a source for iframe.
                        },
                        vimeo: {
                            index: 'vimeo.com/',
                            id: '/',
                            src: '//player.vimeo.com/video/%id%?autoplay=1'
                        },
                        gmaps: {
                            index: '//maps.google.',
                            src: '%id%&output=embed'
                        }

                        // you may add here more sources

                    },

                    srcAction: 'iframe_src', // Templating object key. First part defines CSS selector, second attribute. "iframe_src" means: find "iframe" and set attribute "src".
                }


            });
        })
    }



    // Mask emails from spammers
    // $('.mask-email').each(function() {
    //     var $email = $(this);
    //     var css = $email.attr('class');
    //     // var label = $email.attr('title');
    //     var address = $email.text().replace(/\s*\[at\]\s*/, '@').replace(/\s*\[dot\]\s*/g, '.');
    //     var query = $email.data('query'); // get query string parameters if present
    //     var params = "";
    //     if (query != null) {
    //         params = query;
    //     }
    //     $email.html('<a href="mailto:' + address + params + '" class="' + css + '">' + address + '</a>');
    //     // clear em out
    //     $email.attr('class', '');
    //     $email.attr('data-query', '');
    // });



    //tabbed url
    if (location.hash.substr(0, 2) == "#!") {
        $('[data-toggle="tab"][href="#' + location.hash.substr(2) + '"]').tab('show');
    }

    $("a[data-toggle='tab']").on("shown.bs.tab", function(e) {
        var hash = $(e.target).attr("href");
        if (hash.substr(0, 1) == "#") {
            location.replace("#!" + hash.substr(1));
        }

    });

    if ($('.tab-pane a').length) {
        $('.tab-pane a').on('click', function(e) {
            var $a = $(this);
            var href = $a.attr('href');

            if (href.slice(0, 1) != '#') {
                return;
            }

            e.preventDefault();
            var tabId = href.slice(1);
            $('[data-toggle="tab"][href="' + href + '"]').tab('show');
            // $("html, body").animate({ scrollTop: $('#product-content').offset().top - 150 }, 500);


        });
    }

    if ($('#gallery-banner').length) {
        $('#gallery-banner').on('click', function() {
            $('.nav-tabs a[href="#tab-interest-free-deal"]').click();

        });
    }

    // $("#tab-contact-us").on("shown", function(e) {

    // });

    // $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {

           // alert('d')

    // })


    if ($('.dealer-contact-us').length) {
        $('.dealer-contact-us').on('click', function() {
            $('.nav-tabs a[href="#tab-contact-us"]').click();

            event.preventDefault()
            // alert('d')
        });
    }

    // $('.nav-tabs a[href="#tab-contact-us"]').click();

    window.dispatchEvent(new CustomEvent('init'));

    $(window).on('load resize', function() {
        var $covidHeight = $(".alert--covid").outerHeight();

        $("#main-nav").css("top", $covidHeight);
        $(".hdr-phone").css("top", $covidHeight);
    });


    $('.nav-tabs > li a[title]').tooltip();

    //Wizard 3D PLATTAR
    $('a[data-toggle="tab"]').on('show.bs.tab', function (e) {
        var $target = $(e.target);
        if ($target.parent().hasClass('disabled')) {
            return false;
        }

    });

    // $('a.tab-step').click(function (e) {
    //     toggleRenders($(this));
    // });

    $(".next-step").click(function (e) {


        // find the style
        var $style = '';
        if(typeof $style !== 'undefined'){
            $style  = ($(this).data('style'));
        }

        var $level = '';
        if(typeof $level !== 'undefined'){
            $level  = ($(this).data('level'));
        }

        // set stepper data value from click
        $nextpane = $(this).parents().find('.tab-content');
        $nextpane.attr('data-style', $style)
        $nextpane.attr('data-level', $level);

        var $active = $('.nav-tabs li>.active');
        $active.parent().next().find('.nav-link').removeClass('disabled');

        // find the current active tab
        $step = $('.tab-content').find('.tab-pane.active');

        toggleRenders($style, $level, $step);

        nextTab($active);

    });

    /*
    $(".prev-step").click(function (e) {
        var $active = $('.nav-tabs li>a.active');

        console.log('prev');
        // hide show styles
        var $items = $('.stepper-card');
        $items.each(function(i){

            $(this).removeClass('d-none');

        });

        prevTab($active);
    });
    */



    // search bar
    initSearchBar();

    cookiesPolicyBar();


}]);


function toggleRenders($style, $level, $step){


    // hide show styles for the next tab pane only, retaain current state in current tab
    var $items = $step.next().find('.stepper-card');

    $items.each(function(i){
        if(typeof $style !== 'undefined'  && $style != ''){

            if($(this).hasClass($style)){
                $(this).removeClass('d-none');
            }else{
                $(this).addClass('d-none');
            }
        }

        if(typeof $level !== 'undefined' && $level != ''){
            // console.log('toggle level ' + $level);
            if($(this).hasClass($style) && $(this).hasClass($level)){
                $(this).removeClass('d-none');
            }else{
                $(this).addClass('d-none');
            }
        }

        if($style =='' && $level ==''){
            $(this).removeClass('d-none');
        }

    });

}


// // Accept cookies
function cookiesPolicyBar() {
    // Check cookie
    if (Cookie.get('HideCovidBanner') != "active") {
        $('#cookie').removeClass('d-none');
    }
    //Assign cookie on click
    $('.btn-cookie').on('click', function(e) {
        e.preventDefault();
        Cookie.set('HideCovidBanner', 'active', { expires: 1 }); // cookie will expire in one day
        $('#cookie').addClass('d-none');
        $("#main-nav").css("top", "0px");

        // $("#footer").css("padding-bottom", "20px");
    });
}


// scroll window to form error element and offset fixed header height
function scrollToFormError() {
    // console.log('scrollToFormError', window.location.hash);
    var windowWidth = $(window).width();
    var formStr = '#form-';

    // enquiry form error anchor offset
    if (window.location.hash && window.location.hash.indexOf(formStr) != -1) {

        var formEl = window.location.hash;
        if ($(formEl).length > 0) {

            //Opens contact us tab
            $('.nav-tabs a[href="#tab-contact-us"]').click();

            // var offsetSize = $('.headerfixed').height();
            // console.log(window.location.hash+' '+offsetSize +' '+$(formEl).scrollTop());

            var offsetSize = $('#main-nav').height() + 50;
            if (windowWidth > 767) {
                // offset
                // animate scrollto
                $('html, body').animate({ scrollTop: $(formEl).offset().top - offsetSize }, 1000);
            } else {
                $('html, body').animate({ scrollTop: $(formEl).offset().top - offsetSize }, 1000);
            }

        }
    }

}

function nextTab(elem) {
    $(elem).parent().next().find('a[data-toggle="tab"]').click();
}
function prevTab(elem) {
    $(elem).parent().prev().find('a[data-toggle="tab"]').click();
}

function initSearchBar() {
    if ($('.search-bar').length > 0) {
        $('.search-bar__input').each(function() {

            var searchBar = $(this).closest('.search-bar');
            var form = searchBar.closest('form');
            var submitBtn = form.find('.form-control-feedback');
            $(this).on('change keyup', function() {
                if ($(this).val() != '') {
                    form.addClass('is-filled');
                    searchBar.addClass('hover');
                } else {
                    form.removeClass('is-filled');
                    searchBar.removeClass('hover');
                }
            });

            submitBtn.on('click', function() {
                form.submit();
            });

            form.on('submit', function(e) {
                if ($(this).hasClass('is-filled')) {
                    return true;
                }
                return false;
            });
        });
    }
}



//-----------------------------------------------
// Global AJAX / Application Functions
//-----------------------------------------------
function initAjaxJS() {


    // Infinite Ajax Scroll
    var ias = jQuery.ias({
        container: '#lazyload-items',
        item: '.lazyload-item',
        delay: 1000
    });

    ias.extension(new IASSpinnerExtension({
        html: '<p class="text-center"><img src="/img/site/icons/back-gr-d.svg" alt="" /></p>'
    }));

    // Triggered after new items have rendered.
    // ias.on('rendered', function(items) {
    //     var $items = $(items);

    //     // update match height js
    //     if ($('.mh-item').length > 0) {
    //         // remove the old group
    //         $('.mh-item').matchHeight({ remove: true });

    //         // apply matchHeight on the new selection, which includes the new element
    //         // $('.mh-item').matchHeight();
    //         // $.fn.matchHeight._update();
    //         $.fn.matchHeight._apply($('.mh-item'));
    //     }

    // });
}